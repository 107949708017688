import React, { useEffect, useState, useRef, useCallback } from 'react';
import './entry.css';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    GoogleMap,
    LoadScript,
    DirectionsService,
    DirectionsRenderer,
    Autocomplete,
    useLoadScript
} from '@react-google-maps/api';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const containerStyle = {
    width: '100%',
    height: '580px'
};

const center = {
    lat: -1.286389,
    lng: 36.817223
};

export default function Entry({ storeid,storeLocation }) {

    const origin = storeLocation;
    const [destination, setDestination] = useState('');
    const [response, setResponse] = useState(null);
    const [distance, setDistance] = useState('');
    const [modal, setModal] = useState(false);
    const [orderNumber, setOrdernumber] = useState('');
    const [orderValue, setOrdervalue] = useState('');
    const [customername, setCustomername] = useState('');
    const [gender, setGender] = useState('');
    const [customerphone, setCustomerphone] = useState('');
    const [deliverylocation, setDeliverylocation] = useState('');
    const [landmark, setLandmark] = useState('');
    const [instructions, setInstructions] = useState('');
    const [isDistance, setIsdistance] = useState(false);
    const [specificInstructions, setSpecificinstructions] = useState('');
    const [token, setToken] = useState(null);
    const [response1, setResponse1] = useState(null);
    

    const [orderId, setOrderId] = useState(""); // State for orderId
    const [entries, setEntries] = useState([
        { itemName: "", quantity: "", amount: "" },
    ]);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedEntries = [...entries];
        updatedEntries[index][name] = value;
        setEntries(updatedEntries);
    };

    const handleOrderIdChange = (event) => {
        setOrderId(event.target.value);
    };

    const handleAddEntry = () => {
        setEntries([
            ...entries,
            { itemName: "", quantity: "", amount: "" },
        ]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = "/api/submitEntries";
        try {
            const response = await Axios.post('https://entryapi.albatroxlogistics.com/api/submitItems', { orderId: orderNumber, entries });
            if (response.data.success) {
                setModal(!modal);
            }
            if (!response.data.success) {
                setModal(!modal);
                toast.error('Something went wrong');
            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    const toggle = () => {
        if (orderNumber == '') {
            toast.error('Please enter the order number');
        } else {
            setModal(!modal);
        }
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCJQJxhVpVWG6dS-0mUlgkJ4lAEW-00y5Q', // Replace with your actual API key
        libraries: ['places'],
    });

    const directionsCallback = useCallback((result, status) => {
        if (status === 'OK' && result) {
            setResponse(result);
            const route = result.routes[0];
            const distanceText = route.legs[0].distance.text;
            const distanceValue = parseFloat(distanceText.replace('km', '').trim());
            setDistance(distanceValue);
            setIsdistance(true);
        }
    }, []);

    const handleFindRoute = useCallback(() => {
        if (origin && destination) {
            const DirectionsServiceOptions = {
                destination: destination,
                origin: origin,
                travelMode: 'DRIVING'
            };

            return <DirectionsService options={DirectionsServiceOptions} callback={directionsCallback} />;
        }
        return null;
    }, [origin, destination, directionsCallback]);

    const handleOrder = () => {
        if (orderNumber !== '' && orderValue !== '' && customername !== '' && customerphone !== '' && landmark !== '') {
            handleFindRoute();
        } else {
            toast.error('All fields are required');
        }
    };



    const submitOrder = () => {
        Axios.post('https://entryapi.albatroxlogistics.com/api/postOrder', {
            orderNumber,
            orderValue,
            customername,
            customerphone,
            deliverylocation,
            landmark,
            instructions,
            storeid,
            gender,
            distance
        }).then((res) => {
            if (res.data.success) {
                setOrdernumber("");
                setOrdervalue('');
                setCustomername('');
                setCustomerphone('');
                setDeliverylocation('');
                setLandmark('');
                setInstructions('');
                // postData()
                toast.success('Order submitted successfully');
            }
            if (!res.data.success) {
                toast.error('Something went wrong. Please contact support');
            }
        }).catch((err) => {
            toast.error('Error! Cannot connect to the server. Please contact support');
        });
    };

    const onPlaceSelected = (autocomplete) => {
        const place = autocomplete.getPlace();
        setDeliverylocation(place.formatted_address); // Set the selected place as the delivery location
        setDestination(place.formatted_address); // Update destination state with selected place
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;

    }

    
    const postData = async () => {
        ///Get access token

        const username = 'BImtlrgdjkYTDG1DYXbzyGaUJeLqLnU0';
        const password = 'zVLIFskMr8ma7yRo';
        const credentials = btoa(`${username}:${password}`);
    
        const bodyData = {
          name: 'John Doe',
          email: 'john.doe@example.com'
        };
    
        try {
          const result = await fetch('https://calprsp.collectionafrica.co.ke/api/v1/authenticate?grant_type=client_credentials', {
            method: 'POST',
            headers: {
              'Authorization': `Basic ${credentials}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyData)
          });
    
          const data = await result.json();
          setResponse1(data);
    
          if (data.access_token) {
            setToken(data.access_token); 
            const tk = data.access_token
                // Fetch phone numbers from the backend
            const phoneResponse = await fetch('https://entryapi.albatroxlogistics.com/api/getPhonenumbers'); // Adjust URL
            const phoneNumbers = await phoneResponse.json();

      for (let number of phoneNumbers) {
        const bodyData = {
          sender_id: 'YUNSHU',
          phone_number: number.userphone, // Assuming phone_number field
          message: `You have a new order. Order number #${orderNumber} at ${storeid}.`
        };

        // Send SMS for each phone number
        const result = await fetch('https://calprsp.collectionafrica.co.ke/api/v1/sms/single', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${tk}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        });

        const data = await result.json();
        toast.success(`SMS sent to ${number.userphone}`);
      }
            
           
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };


      const SendSMS = async () => {
        // Replace with your actual token
       
      };



    return (
        <div className='entryWrapper'>
            <ToastContainer />
            <div className="entryMap">
                <div>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                    >
                        {handleFindRoute()}
                        {response && <DirectionsRenderer directions={response} />}
                    </GoogleMap>
                    <div>
                        {distance && <h4>Distance: {distance} km</h4>}
                    </div>
                </div>
            </div>
            <div className="entryFields">
                <h5>Fill the fields below</h5>
                <p style={{ color: 'red' }}>All fields are mandatory*</p>
             
                {/* Order Details Fields */}
                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Order Number">
                    <Form.Control onChange={(e) => { setOrdernumber(e.target.value) }} value={orderNumber} className='inputSearch' type="text" placeholder="Order number" />
                </FloatingLabel>
                <p className='addItems' onClick={toggle}>Add purchase items</p>
                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Order value">
                    <Form.Control onChange={(e) => { setOrdervalue(e.target.value) }} value={orderValue} className='inputSearch' type="text" placeholder="Order value" />
                </FloatingLabel>
                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Customer Title"  >
                    <Form.Select className="selectMeter" aria-label="Floating label select example" onChange={(e) => { setGender(e.target.value) }} >
                        <option selected disabled>Customer title</option>
                        <option value='Male'>Mr</option>
                        <option value='Female'>Miss</option>
                        <option value='Other'>Mrs</option>

                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Customer name">
                    <Form.Control onChange={(e) => { setCustomername(e.target.value) }} value={customername} className='inputSearch' type="text" placeholder="Customer name" />
                </FloatingLabel>
                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Customer phone">
                    <Form.Control onChange={(e) => { setCustomerphone(e.target.value) }} value={customerphone} className='inputSearch' type="text" placeholder="Customer phone" />
                </FloatingLabel>

                {/* Autocomplete for Delivery Location */}
                <Autocomplete
                    onLoad={autocomplete => (window.autocomplete = autocomplete)}
                    onPlaceChanged={() => onPlaceSelected(window.autocomplete)}
                >
                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Delivery location">
                    <Form.Control onChange={e => setDeliverylocation(e.target.value)} value={deliverylocation} className='inputSearch' type="text" placeholder="Delivery location" />
                </FloatingLabel>
                    {/* <input
                        type="text"
                        placeholder="Enter delivery location"
                        className="inputSearch"
                        value={deliverylocation}
                        onChange={e => setDeliverylocation(e.target.value)}
                    /> */}
                </Autocomplete>

                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Landmark">
                    <Form.Control onChange={(e) => { setLandmark(e.target.value) }} value={landmark} className='inputSearch' type="text" placeholder="Landmark" />
                </FloatingLabel>

                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Instructions"  >
                    <Form.Select className="selectMeter"  aria-label="Floating label select example" onChange={(e)=>{setSpecificinstructions(e.target.value)}} >
                        <option selected disabled>Instructions</option>
                        <option value='Call at the gate'>Call at the gate</option>
                        <option value='Call before delivery'>Call before delivery</option>
                        <option value='Call while on the way'>Call while on the way</option>
                                                 
                    </Form.Select>
                    
                </FloatingLabel>
                <FloatingLabel className='formInput' controlId="floatingTextareaGrid" label="Instruction naration">
                    <Form.Control
                        as="textarea"
                        className='inputSearch'
                        placeholder="Instructions naration"
                        onChange={(e) => { setInstructions(e.target.value) }}
                        value={instructions}
                        style={{ height: '100px' }}
                    />
                </FloatingLabel>
                  <br />
                <Button color='success' onClick={submitOrder}>Submit Order</Button>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader>Add purchase items</ModalHeader>
              <div className='modalContainer'>
               
               
                 
              {entries.map((entry, index) => (
          <div key={index} className='entryFields2'>
            <FloatingLabel
              id='iField'
              className="formInput"
              controlId={`itemName-${index}`}
              label={`Item Name ${index + 1}`}
            >
              <Form.Control
                name="itemName"
                onChange={(event) => handleInputChange(index, event)}
                value={entry.itemName}
                className="inputSearch"
                type="text"
                placeholder={`Item Name ${index + 1}`}
              />
            </FloatingLabel>

            <FloatingLabel
              id='iField'
              className="formInput"
              controlId={`quantity-${index}`}
              label={`Quantity ${index + 1}`}
            >
              <Form.Control
                name="quantity"
                onChange={(event) => handleInputChange(index, event)}
                value={entry.quantity}
                className="inputSearch"
                type="number"
                placeholder={`Quantity ${index + 1}`}
              />
            </FloatingLabel>

            <FloatingLabel
              className="formInput"
              controlId={`amount-${index}`}
              label={`Amount ${index + 1}`}
            >
              <Form.Control
                name="amount"
                onChange={(event) => handleInputChange(index, event)}
                value={entry.amount}
                className="inputSearch"
                type="number"
                placeholder={`Amount ${index + 1}`}
              />
            </FloatingLabel>
          </div>
        ))}

                  <div className='itemsButton'>
                    <Button color='warning'  onClick={handleAddEntry}>Add item</Button>
                    <Button onClick={handleSubmit} color='success'>Submit</Button>

                  </div>

                 
               
              </div>
            </Modal>
        </div>
    );
}
